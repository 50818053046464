<template>
    <div v-if="is_load">
        <PacmanLoader />
    </div>
    <div class="reservation_add" v-else-if="store.is_login">
        <form v-on:submit="saveReservation">
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">{{ title }}</h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>מפעל</label>
                                <div v-if="mode != 'view'">
                                    <VueSelect2 v-model="reservation.factory_id" :options="factoryArr" language="he-IL"
                                        :settings="{ multiple: false, MultipleSelection: 'MultipleSelection', dir: 'rtl',tags: true }"
                                        @select="selectMenu()" />
                                </div>
                                <div v-if="mode == 'view'">
                                    <div v-for="m in factoryArr" :key="m.id">
                                        <div v-if="m.id == reservation.factory_id">
                                            {{ m.text }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>שם מלא</label>
                                <InputForm @input="saveData" :value="reservation.full_name" name="full_name" type="text"
                                    v-if="mode != 'view'" />
                                <div v-if="mode == 'view'">{{ reservation.full_name }}</div>
                            </div>

                            <div class="form-group">
                                <label>כתובת</label>
                                <InputForm @input="saveData" :value="reservation.address" name="address" type="text"
                                    v-if="mode != 'view'" />
                                <div v-if="mode == 'view'">{{ reservation.address }}</div>
                            </div>

                            <div class="form-group">
                                <label>טלפון</label>
                                <InputForm @input="saveData" :value="reservation.phone" name="phone" type="text"
                                    v-if="mode != 'view'" />
                                <div v-if="mode == 'view'">{{ reservation.phone }}</div>
                            </div>

                            <div class="form-group">
                                <label>אימייל</label>
                                <InputForm @input="saveData" :value="reservation.mail" name="mail" type="text"
                                    v-if="mode != 'view'" />
                                <div v-if="mode == 'view'">{{ reservation.mail }}</div>
                            </div>

                            <div class="form-group" v-if="mode != 'view'">
                                <label>תאריך</label>
                                <DatePicker v-model="reservation.date" :enableTimePicker="false" format="dd/MM/yyyy"
                                    :dark="store.dark_mode" locale="en" :cancelText="store.dp_lang.cancelText"
                                    :selectText="store.dp_lang.selectText"></Datepicker>
                            </div>
                            <div class="form-group" v-else-if="!isNaN(reservation.date)">
                                <label>תאריך</label><br />
                                {{ convertDate(reservation.date) }}
                            </div>

                            <div class="form-group" v-if="mode != 'view'">
                                <label>שעה</label>
                                <DatePicker v-model="reservation.time" :timePicker="true" format="HH:mm"
                                    :dark="store.dark_mode" locale="he" :cancelText="store.dp_lang.cancelText"
                                    :selectText="store.dp_lang.selectText"></Datepicker>
                            </div>
                            <div class="form-group" v-else-if="reservation.time?.hours || reservation.time?.minutes">
                                <label>שעה</label><br />
                                {{ reservation.time.hours }}:{{ reservation.time.minutes }}
                            </div>

                            <div class="form-group">
                                <label>הערות הלקוח</label>
                                <div v-if="mode != 'view'">
                                    <quill-editor v-model:value="reservation.comment" :options="store.quill_config" />
                                </div>
                                <div v-else v-html="reservation.comment"></div>
                            </div>

                            <div class="form-group">
                                <label>הערות פנימיות</label>
                                <div v-if="mode != 'view'">
                                    <quill-editor v-model:value="reservation.comment_inside"
                                        :options="store.quill_config" />
                                </div>
                                <div v-else v-html="reservation.comment_inside"></div>
                            </div>

                            <div class="form-group">
                                <label>סטטוס</label>
                                <div v-if="mode != 'view'">
                                    <select class="form-control" v-model="reservation.status"> 
                                        <option v-for="(status, index) in statuses" :key="index" :value="index">{{ status }}</option>
                                    </select>
                                </div>
                                <div v-else v-html="statuses[reservation.status]"></div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label>תפריט</label>
                                <div v-if="mode != 'view'">
                                    <VueSelect2 v-model="reservation.menu_id" :options="menuArr" language="he-IL"
                                        :settings="{ multiple: false, MultipleSelection: 'MultipleSelection', dir: 'rtl' }"
                                        @select="selectMenu()" />
                                </div>
                                <div v-if="mode == 'view'">
                                    <div v-for="m in menuArr" :key="m.id">
                                        <div v-if="m.id == reservation.menu_id">
                                            {{ m.text }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-for="p in products" :key="p.menu_id">
                                <div v-if="p.menu_id == reservation.menu_id" class="plist">
                                    <div v-for="mc in p.menu_category" :key="mc.mc_id" class="warp">
                                        <h5>{{ mc.mc_name }}</h5>
                                        <div v-for="product in mc.product" :key="product.product_id">
                                            <div class="row tr"
                                                :class="{ 'bg-secondary': product.count > 0 && mode != 'view' ? true : false }"
                                                v-if="(mode == 'view' && product.count > 0) || mode != 'view'">
                                                <div class="col-md-8">{{ product.product_name }} <small class="text-muted" v-if="product.product_unit">({{ product.product_unit }})</small></div>
                                                <div class="col-md-2">
                                                    <div v-if="mode != 'view'">
                                                        <input type="number" v-model="product.count"
                                                            class="form-control" min="0" step="0.1" />
                                                    </div>
                                                    <div v-if="mode == 'view'">{{ product.count }}</div>
                                                </div>
                                                <div class="col-md-2 price">
                                                    <div v-html="showPrice(product)"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>הנחה</label>
                                        <InputForm @input="saveData" :value="reservation.discount" name="discount"
                                            type="number" v-if="mode != 'view'"  />
                                        <span v-if="mode == 'view'">:&nbsp;{{ reservation.discount }} &#8362;</span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <h4 class="showTotal">
                                        סה"כ: <span>{{ showTotal }} &#8362;</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <input type="submit" class="btn btn-primary" value="שמירה" v-if="mode != 'view'" />
                    <input type="button" class="btn btn-primary" @click="viewToEdit()" value="עריכת הזמנה" v-if="mode == 'view'" />
                    <MiniLoader v-if="mini_load" :center="false" />
                </div>
            </div>
        </form>
    </div>

    <div v-else>
        <NotLogin />
    </div>

</template>
<style scoped>
.plist {
    margin: 43px 0;
    overflow: hidden;
    height: 480px;
    overflow-y: scroll;
}


.plist .warp {
    margin-top: 13px;
}

.plist .tr {
    padding: 7px;
    border-bottom: 1px solid #ced4da;
}

</style>


<script>
import axios from 'axios'
import { log } from '@/utils.js'
import PacmanLoader from '@/components/loaders/PacmanLoader.vue';
import { store } from '@/store'
import InputForm from '@/components/InputForm.vue'
import MiniLoader from '@/components/loaders/MiniLoader'

/*import DataTable from 'datatables.net-vue3'
import Select from 'datatables.net-select';
 
DataTable.use(Select);*/

export default {
    components: {
        PacmanLoader,
        InputForm,
        MiniLoader,
    },
    computed: {
        showTotal: function () {
            let price = 0;
            Object.values(this.products).forEach((p) => {
                if (p.menu_id == this.reservation.menu_id) {
                    Object.values(p.menu_category).forEach((mc) => {
                        if (typeof mc.product != "undefined") {
                            Object.values(mc.product).forEach((product) => {
                                if (product.count > 0) {
                                    price = price + (product.product_price * product.count);
                                }
                            });
                        }
                    });
                }

            });

            price = price - this.reservation.discount;
            if (price <= 0) {
                price = 0;
            }
            return Number(price).toFixed(2);
        }

    },
    watch: {
        '$route'() {
            this.firstDataLoad();
        }
    },
    mounted() {
        this.firstDataLoad();
    },
    data() {
        return {
            store,
            is_load: true,
            mode: "",
            mini_load: false,
            title: '',
            reservation: {},
            menu: {},
            menuArr: [],
            products: [],
            statuses: [],
            factoryArr: [],
        }
    },

    methods: {
        firstDataLoad: function () {
            switch (this.$route.name) {
                case 'reservation_edit':
                    this.mode = "edit"
                    this.title = "עריכת הזמנה";
                    break;
                case 'reservation_view':
                    this.mode = "view"
                    this.title = "צפייה בהזמנה";
                    break;
                default:
                    this.mode = "add";
                    this.title = "הזמנה חדשה";
            }
            
            let get_date = new Date();
            this.reservation = {
                comment: '',
                full_name:  store.user.user_fname + ' ' + store.user.user_lname,
                phone: store.user.user_phone,
                address: store.user.user_address,
                mail: store.user.user_mail,
                comment_inside: '',
                menu_id: 0,
                date:  new Date(),
                time: { hours: get_date.getHours(), minutes: get_date.getMinutes() },
                discount: 0,
                status: 1,
                factory_id: store.user.factory_id,
            };

            this.menu = {};
            this.menuArr = [];
            this.products = [];
            this.statuses = [];
            this.factoryArr = [];

            let formData = new FormData();
            formData.append('key', sessionStorage.getItem('key'));
            if (this.$route.params.id) {
                formData.append('reservation_id', this.$route.params.id);
            }
            axios.post(store.api_url + '/reservation/get_edit_data', formData).then(response => {
                let res = response.data.data;
                //log(res);
                this.menu = res.menu;

                this.factoryArr.push({ 'id': 0, 'text': '== ללא ==' });
                Object.values(res.factories).forEach((obj) => {
                    this.factoryArr.push({ 'id': obj.factory_id, 'text': obj.factory_name });
                });

                Object.values(res.menu).forEach((obj) => {
                    this.menuArr.push({ 'id': obj.menu_id, 'text': obj.menu_name });
                });

                this.products = res.products;
                this.statuses = res.statuses;

                if (this.mode != "add") {
                    let r = res.reservation;
                    this.reservation = {
                        comment: r.reservation_comment,
                        comment_inside: r.reservation_comment_inside,
                        full_name: r.reservation_full_name,
                        phone: r.reservation_phone,
                        address: r.reservation_address,
                        mail: r.reservation_mail,
                        menu_id: r.menu_id,
                        date: new Date(r.reservation_date),
                        discount: r.reservation_discount,
                        status: r.reservation_status,
                        factory_id: r.factory_id,
                    };

                    if (r.reservation_time != "00:00:00") {
                        let time = r.reservation_time.split(":");
                        this.reservation.time = { hours: time[0], minutes: time[1] };
                    }
                    Object.values(res.products).forEach((obj) => {
                        if (obj.menu_id == r.menu_id) {
                            Object.values(obj.menu_category).forEach((mc) => {
                                if (mc?.product) {
                                    Object.values(mc.product).forEach((product) => {
                                        Object.values(res.rp).forEach((rp) => {
                                            //compare
                                            if (product.product_id == rp.product_id) {
                                                this.products[r.menu_id].menu_category[mc.mc_id].product[product.product_id].count = rp.rp_count;
                                            }
                                        });

                                    });
                                }
                            });
                        }
                    });

                    
                }
                this.is_load = false;

            }).catch(function (error) {
                log(error);
            });
        },
        saveData(name, value) {
            this.reservation[name] = value;
        },
        saveReservation: function (e) {
            e.preventDefault();
            if (this.checkValid()) {
                this.mini_load = true;
                let formData = new FormData();
                formData.append('key', sessionStorage.getItem('key'));
                formData.append('total', this.showTotal);
                formData.append('reservation', JSON.stringify(this.reservation));
                formData.append('products', JSON.stringify(this.products));
                if (this.$route.params.id) {
                    formData.append('reservation_id', this.$route.params.id);
                }
                axios.post(store.api_url + '/reservation/save', formData).then(response => {
                    let res = response.data.data;
                    this.mini_load = false;
                    if (store.is_debug) {
                        log(res);
                    } else {
                        this.$router.push("/reservation/view/" + res.reservation_id );
                        this.mode = "view";
                    }
                }).catch(function (error) {
                    log(error);
                });
            }
        },
        checkValid: function () {
            let err = "";
            if (this.showTotal <= 0) {
                err += "הזמנה לא יכולה להיות ללא סכום<br />";
            }
            if (!this.reservation.full_name) {
                err += "יש להכניס שם מלא<br />";
            }
            if (!this.reservation.phone) {
                err += "יש להכניס טלפון<br />";
            }
            if (!this.reservation.date) {
                err += "יש לבחור תאריך<br />";
            }  
            if (!this.reservation.time) {
                err += "יש לבחור שעה<br />";
            }    
            if (err) {
                this.$toast.error(err);
                return false;
            }
            return true;
        },
        selectMenu: function () {

        },
        showPrice(product) {
            let num = product.product_price * product.count;
            return Number(num).toFixed(2) + ' &#8362;';
        },
        convertDate (dateString) {
            var date = new Date(dateString);
            return date.getDate() + "/" + (date.getMonth() < 9 ? '0': '') + (date.getMonth()+1) + "/" + date.getFullYear();
        },
        viewToEdit() {
            this.mode = "edit";
            this.title = "עריכת הזמנה";
        }
    }
}
</script>
