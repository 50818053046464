<template>
    <div>
        <h2>Not Login</h2>
    </div>
</template>

<script>
    export default {
        components: {
        },
        mounted() {
            this.$router.push("login");
        },
    }
</script>