<template>
    <div v-if="is_load">
        <PacmanLoader v-if="false" />
        <MiniLoader />
    </div>
    <div v-else-if="access_denied">
        <h2>הגישה נדחתה</h2><br />
        נראה שאין לך הרשאות לצפות בעמוד זה.<br />
        במידה וקיבלת הודעה זאת בטעות, אנא צור קשר עם מנהלי האתר.
    </div>
    <div class="" v-else-if="store.is_login">
        <div class="row">
        <div class="form-group">
            <label>תאריך</label>
            <DatePicker v-model="date" range :enableTimePicker="false" format="dd/MM/yyyy"
                                    :dark="store.dark_mode" locale="en" :cancelText="store.dp_lang.cancelText"
                                    :selectText="store.dp_lang.selectText" @update:model-value="firstDataLoad"
                                    ></Datepicker>
         </div>
        </div>
        <br />



        <DataTable
    class="display"
    :columns="columns"
    :data="rows"
    ref="table"
    :options="{
        language: store.dataTable.language, 
        lengthMenu: store.dataTable.lengthMenu, 
        iDisplayLength: store.dataTable.iDisplayLength, 
        buttons: store.dataTable.buttons, 
        order: [0, 'desc'],
        responsive: true, 
        dom: 'Bftip'
    }"
 >
 <thead>
        <tr>
            <th>תפריט</th>
            <th>קטגוריה</th>
            <th>מוצר</th>
            <th>כמות</th>
            <th>יחידת מידה</th>
        </tr>
      </thead>
    </DataTable>
    </div>
    <div v-else>
        <NotLogin />
    </div>

</template>
<style scoped>
@import 'datatables.net-dt';
@import "//cdn.datatables.net/buttons/1.5.1/css/buttons.dataTables.min.css";
</style>

<!-- -<script setup>
import { ref, onMounted } from 'vue';

const date = ref();

// For demo purposes assign range from the current date
onMounted(() => {
  const startDate = new Date();
  const endDate = new Date(new Date().setDate(startDate.getDate() + 0));
  date.value = [startDate, endDate];
})
</script> -->
<script>

import axios from 'axios'
import { log } from '@/utils.js'
import PacmanLoader from '@/components/loaders/PacmanLoader.vue';
import MiniLoader from '@/components/loaders/MiniLoader.vue';
import { store } from '@/store'
import DataTable from 'datatables.net-vue3'
import DataTablesLib from 'datatables.net';
import 'datatables.net-responsive';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import { ref } from 'vue';
import jQuery from "jquery";
import moment from 'moment'


const $ = jQuery;
window.$ = $;

DataTable.use(DataTablesLib);
 export default {
    components: {
        PacmanLoader,
        MiniLoader,
        DataTable,
    },

    mounted() {
        this.date = ref();
        this.date = [this.store.getDate.startDate, this.store.getDate.endDate];
        this.firstDataLoad();
    },
    computed: {
    },

    data() {
        return {
            store,
            tender: {},
            error: {},
            storage: {},
            is_load: true,
            access_denied: false,
            date: [],
            rows: [],
            //date: new Date(),
            columns: [
                { data: 'show_menu'},
                { data: 'mc_name'},
                { data: 'product_name' },
                { data: 'count' },
                { data: 'product_unit' },
            ],
        }
    },
    methods: {
        firstDataLoad: function () {
            this.rows = [];
            this.store.getDate.startDate = this.date[0];
            this.store.getDate.endDate = this.date[1];
            let formData = new FormData();
            formData.append('key', sessionStorage.getItem('key'));
            formData.append('date_start', moment(this.date[0]).format("YYYY-MM-DD"));
            formData.append('date_end', moment(this.date[1]).format("YYYY-MM-DD"));
            axios.post(store.api_url + '/reservation/get_cook_list', formData).then(response => {
                let res = response.data.data;

                Object.values(res.products).forEach((obj) => {
                    Object.assign(obj, {show_menu: `<span style="color: ${obj.menu.menu_color}">${obj.menu.menu_name}</span>`});
                    this.rows.push(obj);
                    
                });
                this.is_load = false;

            }).catch(function (error) {
                log(error);
            });
        },
        

    }
}


</script>
