<template>
    <div id="app">
         <div v-if="is_load">
            <PacmanLoader />
        </div>
        <div v-else>
             <div v-if="store.is_login">
                <PanelMain/>
            </div>
            <div v-else>
                <PanelNotLogin />
            </div> 
        </div> 
    </div> 

</template>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    /*@import 'https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css';*/
    @import '@/assets/admin-lte/dist/css/adminlte.min.css';
    @import 'https://cdn.rtlcss.com/bootstrap/v4.2.1/css/bootstrap.min.css';
    @import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700';
    @import '@/assets/styles/style.css';
    @import '@/assets/styles/rtl.css';
    /*@import url('https://fonts.googleapis.com/icon?family=Material+Icons')*/

    
</style>


<script>
    import PanelMain from './views/PanelMain.vue';
    import PanelNotLogin from './views/PanelNotLogin.vue';
    import PacmanLoader  from './components/loaders/PacmanLoader.vue';
    import axios from 'axios'
    import {log} from '@/utils.js'
    import {store} from '@/store'
    window.JSZip = require('jszip');
    
    export default {
        components: {
            'PanelMain': PanelMain,
            'PanelNotLogin': PanelNotLogin,
            'PacmanLoader': PacmanLoader,
        },
        created() {
            if (this.$cookies.get('key')) {
                sessionStorage.setItem('key', this.$cookies.get('key'));
            }
            
            //@TODO remember me on this computer
            if (sessionStorage.getItem('key')) {
                let formData = new FormData();
                formData.append('key', sessionStorage.getItem('key'));
                axios.post(store.api_url+ '/unlogged/is_login',formData).then(response => {
                    let res = response.data.data;

                    if (res.is_login) {
                        store.is_login = true;
                    } else {
                        store.is_login = false;
                    }
                    this.is_load = false;
 
                }).catch(function (error) {
                    log(error);
                });
            } else {
                store.is_login = false;
                this.is_load = false;
            }
        },
        data() {
            return {
                store,
                is_load: true,
            }
        },
        methods: {

        }
    }
</script>