import { store } from '@/store'
import VueCookie from 'vue-cookies'
import router from '@/router'
import axios from 'axios'

export const log = (obj) => {
  console.log(JSON.parse(JSON.stringify(obj)));
}

export const validateEmail = (email) => {
  //eslint-disable-next-line
  return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export const validatPhone = (phone) => {
  //eslint-disable-next-line
  return phone.match(/^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/);
}

export const validatPassword = (pass) => {
  //eslint-disable-next-line
  return pass.match(/^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9]).{8,}$/);
}

export const logout = () => {
  VueCookie.set('key', '')
  sessionStorage.removeItem('key');
  store.is_login = false;
  router.push('/');
  //location.reload();
}

export const showPrice = (price) => {
  return Number(price).toFixed(2) //+ ' ₪'; //&#8362; 
}

export const showDate = (time) => {
  let spliter = time.split(" ");
  let eurotime = spliter[0].split("-");
  let text = `${eurotime[2]}/${eurotime[1]}/${eurotime[0]}`;
  return text;
}

export const showTime = (time) => {
  let spliter = time.split(" ");
  if (spliter.length > 1) {
    let st = spliter[1].split(":");
    return st[0] + ':' + st[1];
  } else {
    let st = time.split(":");
    return st[0] + ':' + st[1];
  }
}

export const showDateTime = (time) => {
  return showDate(time) + ' ' + showTime(time);
}


export const unactiveReservation = (reservation_id, callback) => {

  let formData = new FormData();
  formData.append('reservation_id',reservation_id);
  formData.append('key', sessionStorage.getItem('key'));
  axios.post(store.api_url + '/reservation/unactive_by_id', formData).then(response => {
    callback(response);

  }).catch(function (error) {
    log(error);
  });


}




