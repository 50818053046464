<template>
	<div v-if="is_load">
		<PacmanLoader />
	</div>
	<div class="dashboard" v-else-if="store.is_login">
		<div class="row">
			<div class="col-5">
				<DatePicker
					v-model="date"
					range
					:enableTimePicker="false"
					format="dd/MM/yyyy"
					:dark="store.dark_mode"
					locale="en"
					:cancelText="store.dp_lang.cancelText"
					:selectText="store.dp_lang.selectText"
					@update:model-value="firstDataLoad"
				></DatePicker>

				<Pie id="my-chart-id" :options="chartOptions" :data="chartData" />
			</div>
		</div>
	</div>

	<div v-else>
		<NotLogin />
	</div>
</template>
<style scoped></style>

<script>
import { ref } from "vue";
import axios from "axios";
import { log } from "@/utils.js";
import PacmanLoader from "@/components/loaders/PacmanLoader.vue";
import { store } from "@/store";
import { Pie } from "vue-chartjs";
import moment from "moment";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

/*import DataTable from 'datatables.net-vue3'
import Select from 'datatables.net-select';
 
DataTable.use(Select);*/

export default {
	components: {
		PacmanLoader,
		Pie,
	},
	computed: {},
	watch: {},

	mounted() {
		const now = new Date();
		this.date = ref();
		this.date = [
			new Date(now.getFullYear(), now.getMonth(), 1),
			new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59),
		];

		if (store.user.user_permission < 9) {
			this.$router.push("reservation/add");
		} else {
			this.firstDataLoad();
		}
	},
	data() {
		return {
			store,
			is_load: true,
			chartData: {
				labels: ["הזמנות", "עלות"],
				datasets: [
					{
						data: [0, 0],
						backgroundColor: ["#3C8DBC", "#D2D6DE"],
					},
				],
			},
			chartOptions: {
				responsive: true,
			},
		};
	},
	methods: {
		firstDataLoad: function () {
			let formData = new FormData();
			formData.append("key", sessionStorage.getItem("key"));
			formData.append("date_start", moment(this.date[0]).format("YYYY-MM-DD"));
			formData.append("date_end", moment(this.date[1]).format("YYYY-MM-DD"));
			axios
				.post(store.api_url + "/reservation/get_stats", formData)
				.then((response) => {
					let res = response.data.data;
					log(res);
					this.is_load = false;
					this.test = Math.random();
					this.chartData.datasets[0].data = [res.rtotal, res.itotal];
					this.updateGraph();
				})
				.catch(function (error) {
					log(error);
				});
		},
		updateGraph() {
			this.chartData = {
				...this.chartData,
				datasets: this.chartData.datasets.map((dataset) => {
					return {
						...dataset,
						data: [...this.chartData.datasets[0].data], // Update data array for the existing dataset
					};
				}),
			};
			if (this.chartInstance) {
				this.chartInstance.update();
			}
		},
	},
};
</script>
