<template>
  <div v-if="is_load">
    <PacmanLoader />
  </div>
  <div id="login_template" v-if="!is_load">
    <p class="login-box-msg">טעים, כמו של אמא!</p>
    <form v-on:submit="register">
      <div class="row">
        <div class="col-md-6">
          <InputForm
            v-bind:value="user.user_fname"
            :error="error.user_fname"
            name="user_fname"
            label="שם פרטי"
            type="text"
            placeholder=""
            fa="fa-solid fa-user"
            small=""
            :required="true"
            @input="addUser"
            @change="validation"
            @click="isUsed"
          />
        </div>
        <div class="col-md-6">
          <InputForm
            v-bind:value="user.user_lname"
            :error="error.user_lname"
            name="user_lname"
            label="שם משפחה"
            type="text"
            placeholder=""
            fa="fa-solid fa-user"
            small=""
            :required="true"
            @input="addUser"
            @change="validation"
            @click="isUsed"
          />
        </div>
      </div>
      <InputForm
        v-bind:value="user.user_mail"
        :error="error.user_mail"
        name="user_mail"
        label="אימייל"
        type="email"
        placeholder="example@email.com"
        fa="fa-solid fa-envelope"
        @input="addUser"
        @change="validation"
        :required="true"
      />
      <div class="row">
        <div class="col-md-6">
          <InputForm
            v-bind:value="user.user_password"
            :error="error.user_password"
            name="user_password"
            label="סיסמה"
            type="password"
            fa="fa-solid fa-lock"
            :required="true"
            @input="addUser"
            @change="validation"
            @click="isUsed"
          />
        </div>
        <div class="col-md-6">
          <InputForm
            v-bind:value="user.user_confirm_password"
            :error="error.user_confirm_password"
            name="user_confirm_password"
            label="חזור על סיסמה"
            type="password"
            fa="fa-solid fa-lock"
            :required="true"
            @input="addUser"
            @change="validation"
            @click="isUsed"
          />
        </div>
      </div>
      <InputForm
        v-bind:value="user.user_phone"
        :error="error.user_phone"
        name="user_phone"
        label="טלפון נייד"
        type="tel"
        placeholder=""
        fa="fa-solid fa-mobile-screen"
        small=""
        :required="true"
        @input="addUser"
        @change="validation"
        @click="isUsed"
      />

      <InputForm
        v-bind:value="user.user_address"
        :error="error.user_address"
        name="user_address"
        label="כתובת למשלוח הזמנה"
        type="text"
        placeholder=""
        fa="fa-solid fa-location-dot"
        small=""
        :required="true"
        @input="addUser"
        @change="validation"
        @click="isUsed"
      />

      <div class="form-group">
        <label>מפעל *</label>
          <VueSelect2
            v-model="user.factory_id"
            :options="factoryArr"
            language="he-IL"
            :settings="{
              multiple: false,
              MultipleSelection: 'MultipleSelection',
              dir: 'rtl',
              tags: false,
            }"
          />
      </div>

      <div class="row">
        <div class="col-8"></div>
        <div class="col-4">
          <button type="submit" class="btn btn-primary btn-block">הרשם</button>
        </div>
      </div>
    </form>

    <div class="social-auth-links text-center mb-3"></div>
    <!-- <p class="mb-1">
            <a href="#">I forgot my password</a>
        </p>
        <p class="mb-0">
            <a href="register.html" class="">Register a new membership</a>
        </p> -->
    <p>כבר רשום? <router-link :to="{ name: 'login' }">התחבר עכשיו</router-link><br /></p>
  </div>
</template>

<script>
/* eslint-disable */
import InputForm from "@/components/InputForm.vue";
import { validateEmail, validatPhone, validatPassword, log } from "@/utils.js";
import { store } from "@/store";

import axios from "axios";
export default {
  components: {
    InputForm,
  },
  data() {
    return {
      user: {
        user_mail: "",
        user_password: "",
        user_confirm_password: "",
        user_phone: "",
        user_fname: "",
        user_lname: "",
        user_address: "",
        factory_id: 0,
      },
      error: {
        user_mail: "",
        user_password: "",
        user_confirm_password: "",
        user_phone: "",
        user_fname: "",
        user_lname: "",
        user_address: "",
      },
      factoryArr: [],
      is_load: true,
    };
  },

  watch: {
    $route() {
      this.firstDataLoad();
    },
  },
  mounted() {
    this.firstDataLoad();
  },
  methods: {
    firstDataLoad() {
      axios
        .post(store.api_url + "/unlogged/get_register_first_data")
        .then((response) => {
          let res = response.data.data;
          this.menu = res.menu;

          this.factoryArr.push({ id: 0, text: "== נא לבחור ==" });
          Object.values(res.factories).forEach((value, key) => {
            this.factoryArr.push({ id: key, text: value });
          });

          this.is_load = false;
        })
        .catch(function (error) {
          log(error);
        });
    },
    register(e) {
      e.preventDefault();

      if (!store.is_debug) {
        let is_error = false;

        if (this.user.factory_id == 0) {
            this.$toast.error("אנא בחר מפעל");
            return false;
        }
        Object.entries(this.error).forEach((obj) => {
          if (obj[1]) {
            is_error = true;
          }
        });
        Object.entries(this.user).forEach((obj) => {
          if (!obj[1] && obj[0] != "[object InputEvent]") {
            is_error = true;
            this.error[obj[0]] = "זה הוא שדה חובה";
          }
        });
        if (is_error) {
          this.$toast.error("אנא תקן את כל השגיאות בטופס");
        } else {
          this.submitUser();
        }
      } else {
        this.submitUser();
      }
    },
    addUser(name, value) {
      if (value) {
        this.error[name] = "";
      }
      this.user[name] = value;
    },
    isUsed() {
      //this.isInputTouched[name] = true;
    },
    validation(name, value) {
      switch (name) {
        case "user_mail":
          if (!validateEmail(value)) {
            this.error[name] = "כתובת אימייל אינה תקינה";
          } else {
            this.error[name] = "";
          }
          break;
        case "user_phone":
          if (!validatPhone(value)) {
            this.error[name] = "טלפון אינו תקין";
          } else {
            this.error[name] = "";
          }
          break;
        case "user_confirm_password":
        case "user_password":
          if (
            this.user.user_confirm_password != "" &&
            this.user.user_confirm_password != this.user.user_password
          ) {
            this.error["user_confirm_password"] = "אינו תואם לסיסמה המקורית";
          } else {
            this.error["user_confirm_password"] = "";
          }

          if (!validatPassword(value)) {
            this.error["user_password"] =
              "סיסמה צריכה להיות מינימום 8 תווים ולהכיל אות קטנה, אות גדולה ומספר";
          } else {
            this.error["user_password"] = "";
          }
          break;
      }
    },
    submitUser() {
      let formData = new FormData();
      formData.append("user", JSON.stringify(this.user));
      axios
        .post(store.api_url + "/unlogged/register", formData)
        .then((response) => {
          let res = response.data.data;
          if (res.error_code == 5001) {
            this.error["user_mail"] = "כתובת האימייל כבר בשימוש";
          } else {
            this.$router.push("/");
          }
        })
        .catch(function (error) {
          log(error);
        });
    },
  },
};
</script>
