<template>
    <div class="mb-3">
        <label v-if="label" :for="name">{{label}}{{required ? ' *' :''}}:</label>
        <div :class="{'input-group': true ,'is-invalid': error || checkRequired}">
            <input :type="type" :class="{'form-control': true ,'is-invalid': error  || checkRequired}" :placeholder="placeholder" @input="onInput" :id="name" :name="name" :value="value" :disabled="disabled" @change="onChange" @click="onClick" @blur="onBlur" />
            <div class="input-group-append" v-if="fa || icon">
                <div class="input-group-text">
                    <font-awesome-icon :icon="fa" v-if="fa" /> 
                    <span v-html="icon" v-if="icon"></span>
                </div>
            </div>
        </div>
        <small v-if="small">{{small}}</small>
        <div class="mt-1 error" v-if="error">{{error}}</div>
        <div class="mt-1 error" v-if="!error && checkRequired">זה הוא שדה חובה</div>
    </div>
</template>

<style scoped>
    .error {
        color: #dc3545;
    }
</style>

<script>
    export default {
    props: {
      label: {
        type: String,
        required: false
      },
      placeholder: {
        type: String,
        required: false
      },
      fa: {
        type: String,
        required: false
      },
      icon: {
        type: String,
        required: false
      },
      type: {
        type: String,
        required: false,
        default: 'text'
      },
      value: {
        type: String,
        required: false,
        default: ''
      },
      name: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        required: false,
        default: ''
      },
      small: {
        type: String,
        required: false,
        default: ''
      },
      required: {
        type: Boolean,
        required: false,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
    },
    components: {
    },
    mounted() {
    },
    data(){
        return{
            checkRequired: false
        }
    },
    methods: {
        onInput(event) {
            this.$emit('input', this.name, event.target.value);
        },
       onChange(event) { 
            this.$emit('change', this.name, event.target.value);
            if (this.required && event.target.value == "") {
                this.checkRequired = true;
            } else {
                this.checkRequired = false;
            }
        },
       onClick(event) { 
            this.$emit('click', this.name, event.target.value);
        },
       onBlur(event) { 
            if (this.required && event.target.value == "") {
                this.checkRequired = true;
            } else {
                this.checkRequired = false;
            }
        },
    }
}
</script>