<template>
    <div v-if="is_load">
        <PacmanLoader v-if="false" />
        <MiniLoader />
    </div>
    <div v-else-if="access_denied">
        <h2>הגישה נדחתה</h2><br />
        נראה שאין לך הרשאות לצפות בעמוד זה.<br />
        במידה וקיבלת הודעה זאת בטעות, אנא צור קשר עם מנהלי האתר.
    </div>
    <div class="" v-else-if="store.is_login">
        <div class="row">
        <div class="form-group">
            <label>תאריך</label>
            <DatePicker v-model="date" range :enableTimePicker="false" format="dd/MM/yyyy"
                                    :dark="store.dark_mode" locale="en" :cancelText="store.dp_lang.cancelText"
                                    :selectText="store.dp_lang.selectText" @update:model-value="firstDataLoad"
                                    ></Datepicker>
         </div>
        </div>
        <br />
        <DataTable
    class="display"
    :columns="columns"
    :data="rows"
    ref="table"
    :options="{
        language: store.dataTable.language, 
        lengthMenu: store.dataTable.lengthMenu, 
        iDisplayLength: store.dataTable.iDisplayLength, 
        buttons: store.dataTable.buttons, 
        order: [0, 'desc'],
        responsive: true, 
        dom: 'Bftip'
    }"
 >
 <thead>
        <tr>
            <th>#</th>
            <th>תאריך</th>
            <th>לקוח</th>
            <th>מפעל</th>
            <th>מחיר סופי</th>
            <th>סוג תפריט</th>
            <th>סטטוס</th> 
            <th>הערות לקוח</th>
            <th>מנות</th>
            <th>פעולות</th>
        </tr>
      </thead>
    </DataTable>
        
    </div>
    <div v-else>
        <NotLogin />
    </div>
</template>
<style scoped>
@import 'datatables.net-dt';
@import "//cdn.datatables.net/buttons/1.5.1/css/buttons.dataTables.min.css";

</style>
<script>
import { ref } from 'vue';
import axios from 'axios'
import { log,showDate,showTime,showPrice,unactiveReservation} from '@/utils.js'
import PacmanLoader from '@/components/loaders/PacmanLoader.vue';
import MiniLoader from '@/components/loaders/MiniLoader.vue';
import { store } from '@/store'
import jQuery from "jquery";
import DataTable from 'datatables.net-vue3'
import DataTablesLib from 'datatables.net';
import 'datatables.net-responsive';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import moment from 'moment'

const $ = jQuery;
window.$ = $;



DataTable.use(DataTablesLib);
export default {
    el: 'ReservationList',
    components: {
        PacmanLoader,
        MiniLoader,
        DataTable,
    },
    mounted() {
        this.date = ref();
        this.date = [this.store.getDate.startDate, this.store.getDate.endDate];

        this.firstDataLoad();


        const self = this;
        $(document).on('click', 'table button.view', function() {
            self.$router.push({ name: 'reservation_view', params: { id: $(this).data("id") }});
        });

    },
    computed: {
    },

    data() {
        return {
            store,
            tender: {},
            error: {},
            storage: {},
            is_load: true,
            access_denied: false,
            menus: [],
            statuses: [],
            factories: [],
            rows: [],
            columns: [
                { data: 'reservation_id'},
                { data: 'show_time'},
                { data: 'reservation_full_name' },
                { data: 'factory_name' },
                { data: 'total_price' },
                { data: 'menu' },
                { data: 'status' },
                { data: 'reservation_comment' },
                { data: 'product' },
                { data: 'actions' },
            ],
        }
    },
    methods: {
        firstDataLoad: function () {
            this.rows = [];
            this.store.getDate.startDate = this.date[0];
            this.store.getDate.endDate = this.date[1];
            let product_text;

            let formData = new FormData();
            formData.append('key', sessionStorage.getItem('key'));
            formData.append('date_start', moment(this.date[0]).format("YYYY-MM-DD"));
            formData.append('date_end', moment(this.date[1]).format("YYYY-MM-DD"));
            axios.post(store.api_url + '/reservation/get_packing_list', formData).then(response => {
                let res = response.data.data;

                Object.values(res.reservatinos).forEach((obj) => {
                    Object.assign(obj, {show_time:  `<span class="hide">${obj.reservation_date} ${obj.reservation_time}</span>${showDate(obj.reservation_date)} ${showTime(obj.reservation_time)}</span>`});
                    Object.assign(obj, {total_price:  `${showPrice(obj.reservation_total_price)}`});
                    Object.assign(obj, {factory_name:  obj.factory_id == 0 ? '' : res.factories[obj.factory_id]});
                    Object.assign(obj, {menu:  `<span style="color: ${res.menus[obj.menu_id].menu_color};">${res.menus[obj.menu_id].menu_name}</span>`});
                    Object.assign(obj, {status:  res.statuses[obj.reservation_status]});
                    Object.assign(obj, {reservation_comment: obj.reservation_comment});
                    product_text="";
                    Object.values(res.reservation_products).forEach((p) => {
                        if (obj.reservation_id == p.reservation_id) {
                            product_text += `${p.rp_count} ${p.product_name} ${p.product_unit}<br />`;
                        }
                    });
                    Object.assign(obj, {product: product_text});
                    Object.assign(obj, {actions: `
                        <div class="action_btn">
                            <button class="btn btn-default view" title="צפייה בהזמנה" data-id="${obj.reservation_id}">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
                            </button>
                        </div>`
                    })
                  
                    
                    this.rows.push(obj);
                    
                });
                this.is_load = false;
                this.menus = res.menus;
                this.statuses = res.statuses;
                this.factories = res.factories;


            }).catch(function (error) {
                log(error);
            });
        },
        deleteReservation: function (reservation_id,elem) {
            if (confirm("האם ברצונך למחוק הזמנה זו?")) {
                unactiveReservation(reservation_id,function(response){
                    let res = response.data.data;
                    if (res.success) {
                        elem.closest("tr").hide();
                    }
                });
            }

        },

        test: function () {
            this.rows = [];

        },

    }
}


</script>
