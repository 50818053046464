import { createRouter, createWebHistory } from 'vue-router'
//import store from '@/store/index';
/*import VueRouter from 'vue-router'
import TenderAdd from '../views/TenderAdd.vue'
import TenderView from '../views/TenderView.vue'
import TenderMy from '../views/TenderMy.vue'
import TenderAll from '../views/TenderAll.vue'
import TenderWaitBid from '../views/TenderWaitBid.vue'*/
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import CrudView from '../components/CrudView.vue'
import DashboardView from '../views/DashboardView.vue'
import ReservationAdd from '../views/ReservationAdd.vue'
import ReservationList from '../views/ReservationList.vue'
import ReservationCook from '../views/ReservationCook.vue'
import ReservationPacking from '../views/ReservationPacking.vue'



//import {log} from '@/utils.js'


const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView,
    meta: {
        loginRequired: true
    }
  },
  {
    path: '/menu',
    name: 'menu',
    component: CrudView,
    meta: {
        loginRequired: true,
        crud: 'menu' 
    }
  },
  {
    path: '/product',
    name: 'product',
    component: CrudView,
    meta: {
        loginRequired: true,
        crud: 'product' 
    }
  },
  {
    path: '/reservation/add',
    name: 'reservation_add',
    component: ReservationAdd,
    meta: {
        loginRequired: true,
    }
  },
  {
    path: '/reservation/list',
    name: 'reservation_list',
    component: ReservationList,
    meta: {
        loginRequired: true,
    }
  },
  {
    path: '/reservation/packing',
    name: 'reservation_packing',
    component: ReservationPacking,
    meta: {
        loginRequired: true,
    }
  },
  
  {
    path: '/reservation/cook',
    name: 'reservation_cook',
    component: ReservationCook,
    meta: {
        loginRequired: true,
    }
  },
  {
    path: '/reservation/edit/:id',
    name: 'reservation_edit',
    component: ReservationAdd,
    meta: {
        loginRequired: true,
    }
  },
  {
    path: '/reservation/view/:id',
    name: 'reservation_view',
    component: ReservationAdd,
    meta: {
        loginRequired: true,
    }
  },
  {
    path: '/user_crud',
    name: 'user_crud',
    component: CrudView,
    meta: {
        loginRequired: true,
        crud: 'user',
    }
  },
  {
    path: '/user_crud',
    name: 'user_crud',
    component: CrudView,
    meta: {
        loginRequired: true,
        crud: 'user',
    }
  },
  {
    path: '/ingredient_crud',
    name: 'ingredient_crud',
    component: CrudView,
    meta: {
        loginRequired: true,
        crud: 'ingredient',
    }
  },
  
  {
    path: '/factory_crud',
    name: 'factory_crud',
    component: CrudView,
    meta: {
        loginRequired: true,
        crud: 'factory' 
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/register/',
    name: 'register',
    component: RegisterView,
    meta: {
        loginRequired: false
    }
  },
  /*{
    path: '/register/:type',
    name: 'registerPro',
    component: RegisterView,
    meta: {
        loginRequired: false
    }
    /*beforeEnter: (to, from, next) => { 
        next();
    },
  },
  {
    path: '/tender/:id',
    name: 'tender_view',
    component: TenderView,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/tender_my',
    name: 'tender_my',
    component: TenderMy,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/tender_wait_bid',
    name: 'tender_wait_bid',
    component: TenderWaitBid,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/tender_all',
    name: 'tender_all',
    component: TenderAll,
    meta: {
        loginRequired: false
    }
  },*/
  /*{
    path: '/tender_edit/:id',
    name: 'tender_edit',
    component: TenderAdd,
    meta: {
        loginRequired: false
    }
  },*/
  
]



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router


/* eslint-disable */

/*router.beforeEach((to, from, next) => {
    alert(to.meta.loginRequired)
    /*console.log(store.state.is_login)
    next()
    /*if (!store.state.is_login) {
        next('/login');
    } else if (to.meta.requiresUnauth && !!store.getters['auth/token']) {
        next('/');
    } else {
        next();
    }
});*/