import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import { Service } from 'axios-middleware';
import VueCookies from 'vue-cookies'
import { store } from './store'
import router from './router'
import NotLogin from './components/NotLogin.vue';
import {log,logout} from '@/utils.js'
import { quillEditor } from 'vue3-quill'
import Select2 from 'vue3-select2-component';
import { library } from "@fortawesome/fontawesome-svg-core";

import {createToaster} from "@meforma/vue-toaster";
import Toaster from "@meforma/vue-toaster";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

//import { GridGlobal } from 'gridjs-vue'


//import VueApexCharts from 'vue-apexcharts'
//import SmartTable from 'vuejs-smart-table'

const toaster = createToaster({'position': 'top-left'});

import { 
    faEnvelope, 
    faLock, 
    faMobileScreen, 
    faUser, 
    faBars, 
    faSun, 
    faMoon,
    faHouse,
    faUserGear,
    faToolbox,
    faScrewdriverWrench,
    faTrashCan,
    faBucket,
    faTrowel,
    faStar,
    faEdit,
    faRightFromBracket,
    faPlus,
    faHelmetSafety,
    faBoxOpen,
    faUtensils,
    faBowlFood,
    faEye,
    faList,
    faTrash,
    faBook,
    faIndustry,
    faLocationDot,
    faPepperHot
} from "@fortawesome/free-solid-svg-icons";

import { 
    faComments as farComments, 
    faBell as farBell, 
    faSun as farSun, 
    faMoon as farMoon,
    faTrashCan as farTrashCan,
    //faHouse as farHouse
} from '@fortawesome/free-regular-svg-icons'

library.add( faEnvelope, 
    faLock, 
    faMobileScreen, 
    faUser, 
    faBars, 
    faSun, 
    faMoon,
    faHouse,
    faUserGear,
    faToolbox,
    faScrewdriverWrench,
    faTrashCan,
    faBucket,
    faTrowel,
    faStar,
    faEdit,
    faRightFromBracket,
    faPlus,
    faHelmetSafety,
    faBoxOpen,
    farComments,
    farBell,
    farSun,
    farMoon,
    farTrashCan,
    faUtensils,
    faBowlFood,
    faEye,
    faList,
    faTrash,
    faBook,
    faIndustry,
    faLocationDot,
    faPepperHot
);

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})


import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

const service = new Service(axios);

service.register({
    onRequest(config) {
        return config;
    },
    onResponse(response) {
        if (process.env.NODE_ENV == "development") {
            log(response);
        }
        let obj = JSON.parse(response.data);
        let res = obj.data
        if (res.error) {
            toaster.error(res.error_msg)
        }
        if (res.success) {
            toaster.success(res.success_msg)
        }
        if (res.no_access == "logout") {
            logout();
        }
        if (res.user) {
            store.user = res.user;
        }
        return response;
    },
});




const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(VueCookies);
app.use(quillEditor);

app.use(Toaster, {'position': 'top-left'});
app.component('VueSelect2', Select2); 
app.component('NotLogin', NotLogin);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component('DatePicker', Datepicker);

document.title = 'SIMON - אוכל ביתי';


app.mount('#app')
