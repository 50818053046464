<template>
    <div id="crud">
        <iframe :src="store.api_url+'/crud/'+$route.meta.crud"></iframe> 
    </div>
</template>

<style scoped>
    iframe {
        width: 100%;
        height: 90vh;
        border: 0;
    }
</style>

<script>
    import {store} from '@/store'
    export default {
        created() {
            /*alert(this.api_url);
            alert(this.$route.meta.crud);*/
        },
        data() {
            return {
                store,
            }
        },
    }
</script>