<template>
        <div class="hold-transition login-page">
        <div class="login-box">
         <v-img src="@/assets/logo2.png" id="logo" />

            <div class="card">
                <div class="card-body login-card-body">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

    
    #logo {
        width: 300px;
        margin: 0 auto 20px auto;
        display: block;
    }

    .hold-transition {
        background: url(@/assets/iStock-615078560.jpg);
        background-size: cover;
    }
    
</style>

<script>
    export default {
        mounted() {
            document.body.classList.add('hold-transition', 'login-page');
        },
    }
</script>